<template>
  <div class="mt-2">
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <feather-icon
            icon="ClockIcon"
            size="24"
          />
          <p id="cancel-label">
            Estamos creando la transacción...
          </p>
        </div>
      </template>
      <div v-if="travelData.status_id != 3">
        <b-alert
          variant="primary"
          :show="travelData.status_id != 3"
        >
          <h4 class="alert-heading">
            La transacción que buscas se encuentra ya en un estado diferente el Pendiente
          </h4>
          <div class="alert-body">
            Te recomendamos consultar la
            <b-link
              v-if="userData.id"
              class="alert-link"
              :to="{ name: 'Profile'}"
            >
              lista de transacciones
            </b-link>
            o consulta la
            <b-link
              v-if="Object.keys(travelData).length > 0"
              class="alert-link"
              :to="{ name: 'PaymentsResult', params: { payment_id: travelData.transaction[0]['id_transaction'] }}"
            >
              última información sobre la transacción
            </b-link>
          </div>
        </b-alert>
      </div>
      <b-row v-if="travelData.status_id == 3">
        <b-col
          cols="12"
          md="8"
        >
          <h3 class="text-primary">
            Información de pasajeros
          </h3>
          <validation-observer
            ref="usersForm"
          >
            <b-form @submit.prevent>
              <b-card
                v-for="(item, key) in travelData.chairs_select"
                :key="key"
              >
                <template #header>
                  <h4 class="mb-0 text-primary">
                    {{ key == 0 ? `Pasajero Principal` : `Pasajero` }}
                  </h4>
                  <b-badge variant="light-primary">
                    Silla {{ item.label }}
                  </b-badge>
                </template>
                <b-row>
                  <b-col
                    cols="12"
                    sm="4"
                  >
                    <b-form-group
                      label-for="Nombres"
                      label="Nombres"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Nombres"
                        :vid="`FirstName${key}`"
                        rules="required"
                      >
                        <b-form-input
                          :id="`FirstName${key}`"
                          v-model="item.first_name"
                          :name="`${key}FirstName`"
                          placeholder="Nombres"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="4"
                  >
                    <b-form-group
                      label-for="Apellidos"
                      label="Apellidos"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Apellidos"
                        rules="required"
                        :vid="`LastName${key}`"
                      >
                        <b-form-input
                          v-model="item.last_name"
                          name="login-LastName"
                          placeholder="Apellidos"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="4"
                  >
                    <b-form-group
                      label="Genero"
                      label-for="users-genero"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Genero"
                        rules="required"
                        :vid="`Gender${key}`"
                      >
                        <b-form-select
                          v-model="item.gender"
                          :state="errors.length > 0 ? false : null"
                          :options="optionsGender"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="4"
                  >
                    <b-form-group
                      label="Tipo de documento"
                      label-for="documento"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tipo de documento"
                        rules="required"
                        :vid="`DocumentType${key}`"
                      >
                        <b-form-select
                          v-model="item.type_document"
                          :state="errors.length > 0 ? false : null"
                          :options="optionsTypeDocument"
                          value-field="homolog_id"
                        >
                          <b-form-select-option :value="null">
                            Selecciona
                          </b-form-select-option>
                        </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="4"
                  >
                    <b-form-group
                      label-for="Número de documento"
                      label="Número de documento"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Número de documento"
                        rules="required"
                        :vid="`Document${key}`"
                      >
                        <b-form-input
                          v-model="item.document"
                          name="document"
                          placeholder="Documento"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="4"
                  >
                    <b-form-group
                      label-for="Fecha de nacimiento"
                      label="Fecha de nacimiento"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Fecha de nacimiento"
                        rules="required"
                        :vid="`DateOfBirth${key}`"
                      >
                        <VueDatePicker
                          v-model="item.date_of_birth"
                          fullscreen-mobile
                          placeholder="Fecha de nacimiento"
                          color="#152848"
                          clearable
                          :visible-years-number="80"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="4"
                    v-if="item.NacionalidadPaisID != 170"
                  >
                    <b-form-group
                      label=" País de Nacionalidad"
                      label-for="users-nacionalidad"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Nacionalidad"
                        rules="required"
                        :vid="`Nacionalidad${key}`"
                      >
                        <b-form-select
                          v-model="item.NacionalidadPaisID"
                          :state="errors.length > 0 ? false : null"
                          :options="optionsNacionalidad"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row
                  v-if="key == 0"
                >
                  <div>
                    <h4 class="mb-2 ml-1 text-primary">
                      Enviaremos el tiquete a la siguiente dirección de correo electrónico
                    </h4>
                  </div>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      label="Correo electronico"
                      label-for="users-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                        :vid="`Email${key}`"
                      >
                        <b-form-input
                          id="users-email"
                          v-model="item.email"
                          placeholder="Correo electronico"
                          :state="errors.length > 0 ? false : null"
                          name="users-email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      label-for="Teléfono"
                      label="Teléfono"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Teléfono"
                        rules="required"
                        :vid="`Tlf${key}`"
                      >
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            🇨🇴 (+57)
                          </b-input-group-prepend>
                          <cleave
                            id="phone"
                            v-model="item.telefono"
                            class="form-control"
                            :raw="false"
                            :options="phone"
                            placeholder="1234 567 8900"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-form>
          </validation-observer>
          <b-card>
            <template #header>
              <h4 class="mb-0 text-primary">
                Elige un medio de pago
              </h4>
            </template>
            <b-list-group flush>
              <app-collapse
                accordion
              >
                <app-collapse-item
                  v-for="(item, key) in optionsTypePayment"
                  :key="key"
                  title=""
                  @click="setPayment(item)"
                >
                  <div
                    slot="header"
                    @click="setPayment(item)"
                  >
                    <b-row>
                      <div>
                        <h5 class="mr-1 mt-1">
                          {{ item.name }}
                        </h5>
                      </div>
                      <b-img
                        :width="item.id == 3 ? 70: 500"
                        :height="item.id == 3 ? 200: 120"
                        :src="setImg(item.photo)"
                        fluid
                        alt="Responsive image"
                      />
                    </b-row>
                  </div>
                  <b-container v-if="item.id == 1 || item.id == 2">
                    <b-row>
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          label-for="Nombre y Apellido"
                          label="Nombre y Apellido"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Nombre y Apellido"
                            :vid="`FirstName${key}`"
                            rules="required"
                            immediate
                          >
                            <b-form-input
                              id="Nombre y Apellido"
                              v-model="item.full_name"
                              name="Nombre y Apellido"
                              placeholder="Nombres"
                              :state="errors.length > 0 ? false:null"
                              @update="setPayment(item)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        sm="6"
                      >
                        <b-form-group
                          label="Tipo de documento"
                          label-for="documento"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Tipo de documento"
                            rules="required"
                            :vid="`DocumentType`"
                            immediate
                          >
                            <b-form-select
                              v-model="item.legal_id_type"
                              :state="errors.length > 0 ? false : null"
                              :options="optionsTypeDocumentWompi"
                              @update="setPayment(item)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        sm="6"
                      >
                        <b-form-group
                          label-for="Número de documento"
                          label="Número de documento"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Número de documento"
                            rules="required"
                            :vid="`Document`"
                            immediate
                          >
                            <b-form-input
                              v-model="item.legal_id"
                              name="document"
                              placeholder="Documento"
                              :state="errors.length > 0 ? false:null"
                              @update="setPayment(item)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        sm="6"
                      >
                        <b-form-group
                          label="Correo electronico"
                          label-for="users-email"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Email"
                            rules="required|email"
                            :vid="`Email${key}`"
                            immediate
                          >
                            <b-form-input
                              id="users-email"
                              v-model="item.email"
                              placeholder="Correo electronico"
                              :state="errors.length > 0 ? false : null"
                              name="users-email"
                              @update="setPayment(item)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        sm="6"
                      >
                        <b-form-group
                          label-for="Teléfono"
                          label="Teléfono"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Teléfono"
                            rules="required"
                            :vid="`Tlf`"
                            immediate
                          >
                            <b-input-group>
                              <b-input-group-prepend is-text>
                                🇨🇴 (+57)
                              </b-input-group-prepend>
                              <cleave
                                id="phone"
                                v-model="item.phone_number"
                                class="form-control"
                                :raw="false"
                                :options="phone"
                                placeholder="1234 567 8900"
                                @update="setPayment(item)"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-container>
                  <b-container v-if="item.id == 3">
                    <b-alert
                      variant="primary"
                      show
                    >
                      <h4 class="alert-heading">
                        Al utilizar este metodo de pago seras redirigido a una nueva página con el QR, En donde deberas subir el comprobante del pago
                      </h4>
                    </b-alert>
                    <b-row>
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          label-for="Nombre y Apellido"
                          label="Nombre y Apellido"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Nombre y Apellido"
                            :vid="`FirstName${key}`"
                            rules="required"
                            immediate
                          >
                            <b-form-input
                              id="Nombre y Apellido"
                              v-model="item.full_name"
                              name="Nombre y Apellido"
                              placeholder="Nombres"
                              :state="errors.length > 0 ? false:null"
                              @update="setPayment(item)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        sm="6"
                      >
                        <b-form-group
                          label="Tipo de documento"
                          label-for="documento"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Tipo de documento"
                            rules="required"
                            :vid="`DocumentType`"
                            immediate
                          >
                            <b-form-select
                              v-model="item.legal_id_type"
                              :state="errors.length > 0 ? false : null"
                              :options="optionsTypeDocumentWompi"
                              @update="setPayment(item)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        sm="6"
                      >
                        <b-form-group
                          label-for="Número de documento"
                          label="Número de documento"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Número de documento"
                            rules="required"
                            :vid="`Document`"
                            immediate
                          >
                            <b-form-input
                              v-model="item.legal_id"
                              name="document"
                              placeholder="Documento"
                              :state="errors.length > 0 ? false:null"
                              @update="setPayment(item)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        sm="6"
                      >
                        <b-form-group
                          label="Correo electronico"
                          label-for="users-email"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Email"
                            rules="required|email"
                            :vid="`Email${key}`"
                            immediate
                          >
                            <b-form-input
                              id="users-email"
                              v-model="item.email"
                              placeholder="Correo electronico"
                              :state="errors.length > 0 ? false : null"
                              name="users-email"
                              @update="setPayment(item)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        sm="6"
                      >
                        <b-form-group
                          label-for="Teléfono"
                          label="Teléfono"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Teléfono"
                            rules="required"
                            :vid="`Tlf`"
                            immediate
                          >
                            <b-input-group>
                              <b-input-group-prepend is-text>
                                🇨🇴 (+57)
                              </b-input-group-prepend>
                              <cleave
                                id="phone"
                                v-model="item.phone_number"
                                class="form-control"
                                :raw="false"
                                :options="phone"
                                placeholder="1234 567 8900"
                                @update="setPayment(item)"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-container>
                </app-collapse-item>
              </app-collapse>
            </b-list-group>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <h3 class="text-primary">
            Detalles de tu Reserva
          </h3>
          <vue-detail
            v-if="Object.keys(travelData).length > 0"
            :travel-data="travelData"
            :type-payment="type_payment"
            :is-active-pay="true"
            @total_sum="setAmount"
          />
          <b-button
            variant="primary"
            block
            @click="onSubmit"
          >
            pagar
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>
    <vue-qr-form
      v-if="modalShow"
      :modal-show="modalShow"
      :data-pay="parseInt(type_payment_id)"
      :amount-in-cents="amount_in_cents"
      :reference="reference"
      :customer-data="{
        email: email,
        fullName: full_name,
        phoneNumber: optionsTypePayment.find(element => element.id == travelData.type_payment_id).phone_number,
        phoneNumberPrefix: '+57',
        legalId: legal_id,
        legalIdType: legal_id_type
      }"
      @close="close"
    />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton, BCard, BRow, BCol, BBadge, BListGroup,
  BFormGroup, BFormInput, BForm, BFormSelect, BInputGroup, BInputGroupPrepend, BFormSelectOption, BImg, BOverlay, BContainer, BAlert, BLink,
} from 'bootstrap-vue'
import DetailReserva from '@/components/Payments/DetailReserva.vue'
import QRTransferencia from '@/views/Payments/QR.vue'
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import Cleave from 'vue-cleave-component'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'
import 'cleave.js/dist/addons/cleave-phone.us'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { optionsTypeDocumentWompi, optionsGender, optionsNacionalidad } from '@/libs/utils'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import 'moment/locale/es'

export default {
  name: 'PaymentComponent',
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BBadge,
    BListGroup,
    BContainer,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelectOption,
    AppCollapse,
    AppCollapseItem,
    BOverlay,
    BImg,
    BAlert,
    BLink,
    ValidationObserver,
    ValidationProvider,
    VueDatePicker,
    Cleave,
    'vue-detail': DetailReserva,
    'vue-qr-form': QRTransferencia,
  },
  data() {
    return {
      travelData: {},
      paymentData: {},

      phone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      showOverlay: false,
      optionsTypeDocument: null,
      optionsTypePayment: null,
      optionsTypeDocumentWompi,
      optionsNacionalidad,
      optionsGender,

      type_payment: {
        transaction_fee: 0,
      },

      // data wompi
      type_payment_id: null,
      email: null,
      full_name: null,
      legal_id: null, // numero de documento
      legal_id_type: null, // tipo de documento
      public_key: null,
      currency: 'COP',
      amount_in_cents: null,
      reference: null,
      data_signature_integrity: null,

      modalShow: false,

    }
  },
  computed: {
    ...mapGetters({ userData: 'userApp/getProfileData' }),
  },
  async mounted() {
    this.showOverlay = true
    this.travelData = await this.getTransaction()
    this.showOverlay = false
    this.reference = `${this.travelData.viaje_id}-${this.travelData.total_price}-${this.travelData.id}-${this.travelData.company_id}-${moment(). format('YYYY-MM-DD-hh-mm-ss')}`
    if (localStorage.getItem('accessToken')) {
      this.getTypeDocumentLogin()
      this.getTypePaymentLogin()
    } else {
      this.getTypeDocument()
      this.getTypePayment()
    }
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute(
      'src',
      'https://checkout.wompi.co/widget.js',
    )
    document.head.appendChild(recaptchaScript)
  },
  methods: {
    setAmount(value) {
      this.amount_in_cents = value
    },
    close() {
      this.modalShow = !this.modalShow
    },
    setImg(value) {
      return `${process.env.VUE_APP_URL_STORAGE}${value}`
    },
    getTypeDocument() {
      this.$http.get('/v1/type_document/web?filter[status.id]=1', {
        params: {
          company_id: this.travelData.company_id,
        },
      }).then(res => {
        this.optionsTypeDocument = res.data.data
      })
    },
    getTypeDocumentLogin() {
      this.$http.get('/v1/type-document?filter[status.id]=1', {
        params: {
          company_id: this.travelData.company_id,
        },
      }).then(res => {
        this.optionsTypeDocument = res.data.data
      })
    },
    getTypePayment() {
      this.$http.get('/v1/type_payment/web?filter[status.id]=1').then(res => {
        this.optionsTypePayment = res.data.data
      })
    },
    getTypePaymentLogin() {
      this.$http.get('/v1/type-payment?filter[status.id]=1').then(res => {
        this.optionsTypePayment = res.data.data
      })
    },
    getTransaction() {
      const result = this.$http.get(`/v1/travel/transaction/${this.$route.params.payment_id}`)
        .then(res => res.data.data)
      return result
    },
    async generateIntegrity(reference, monto, moneda, integrity) {
      const cadenaConcatenada = `${reference}${monto}${moneda}${integrity}`

      const encondedText = new TextEncoder().encode(cadenaConcatenada)
      const hashBuffer = await crypto.subtle.digest('SHA-256', encondedText)
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
      return hashHex
    },
    async setPayment(value) {
      this.type_payment = value
      switch (value.id) {
        // Test Bancolombia
        case 1:
          this.travelData.type_payment_id = value.id
          this.type_payment_id = value.id
          this.data_signature_integrity = await this.generateIntegrity()
          this.email = value.email
          this.full_name = value.full_name
          this.legal_id = value.legal_id
          this.legal_id_type = value.legal_id_type
          this.public_key = JSON.parse(value.data_of_payment).pub_public
          // this.amount_in_cents = this.travelData.total_price
          this.data_signature_integrity = await this.generateIntegrity(
            this.reference,
            this.travelData.total_price,
            this.currency,
            JSON.parse(value.data_of_payment).test_public,
          )
          break
        // Prod bancolombia
        case 2:
          this.travelData.type_payment_id = value.id
          this.type_payment_id = value.id
          this.data_signature_integrity = await this.generateIntegrity()
          this.email = value.email
          this.full_name = value.full_name
          this.legal_id = value.legal_id
          this.legal_id_type = value.legal_id_type
          this.public_key = JSON.parse(value.data_of_payment).pub_public
          // this.amount_in_cents = this.travelData.total_price
          this.data_signature_integrity = await this.generateIntegrity(
            this.reference,
            this.travelData.total_price,
            this.currency,
            JSON.parse(value.data_of_payment).test_public,
          )
          break
        // QR Transferencia bancolombia
        case 3:
          this.travelData.type_payment_id = value.id
          this.type_payment_id = value.id
          this.data_signature_integrity = await this.generateIntegrity()
          this.email = value.email
          this.full_name = value.full_name
          this.legal_id = value.legal_id
          this.legal_id_type = value.legal_id_type
          this.type_payment.transaction_fee = value.transaction_fee
          // this.amount_in_cents = this.travelData.total_price
          this.data_signature_integrity = await this.generateIntegrity(
            this.reference,
            this.travelData.total_price,
            this.currency,
            JSON.parse(value.data_of_payment).test_public,
          )
          break
        default:
          break
      }
    },
    setDocument() {
      let documentSet = this.travelData.chairs_select[0].document
      if (this.userData.document != '') {
        documentSet = this.userData.document
      }
      return documentSet
    },
    setEmail() {
      let emailSet = this.travelData.chairs_select[0].email
      if (this.userData.email != '') {
        emailSet = this.userData.email
      }
      return emailSet
    },
    setPhoneNumber() {
      let telefonoSet = this.travelData.chairs_select[0].telefono
      if (this.userData.phone_number != '') {
        telefonoSet = this.userData.phone_number
      }
      return telefonoSet
    },
    async saveTicket() {
      this.showOverlay = true
      this.$http.post(`/v1/travel/transaction/${this.travelData.id}`, {
        company_id: this.travelData.company_id,
        user_id: this.userData.id,
        document: await this.setDocument(),
        email: await this.setEmail(),
        telefono: await this.setPhoneNumber(),
        id_source: this.travelData.id_source,
        id_destination: this.travelData.id_destination,
        source: this.travelData.source,
        destination: this.travelData.destination,
        departure_date: this.travelData.departure_date,
        arrival_date: this.travelData.arrival_date,
        chairs_select: this.travelData.chairs_select,
        viaje_id: this.travelData.viaje_id,
        unit_price: this.travelData.unit_price,
        total_price: this.travelData.total_price,
        TransaccionID: '',
        type_payment_id: this.travelData.type_payment_id,
        status_id: 3,
        fecViaje: this.travelData.departureDate,
        codEmpresa: this.travelData.companyCodeLine,
        hora: this.travelData.hora,
        adic: this.travelData.adic,
        lineCode: this.travelData.lineCode,
      }).then(() => {
        this.showOverlay = false
      })
    },
    validateMethodPay() {
      if (this.type_payment_id == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mensaje',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Debes seleccionar un metodo de pago y rellenar el formulario',
          },
        },
        {
          position: 'bottom-center',
        })
        return false
      }
      return true
    },
    onSubmit() {
      this.$refs.usersForm.validate().then(success => {
        if (success) {
          if (this.validateMethodPay()) {
            switch (this.travelData.type_payment_id) {
              // Test Bancolombia
              case 1:
                this.saveTicket()
                this.onSubmitWompi()
                break
              // Prod bancolombia
              case 2:
                this.saveTicket()
                this.onSubmitWompi()
                break
              case 3:
                this.saveTicket()
                this.onRouteQR()
                break
              default:
                break
            }
          }
        }
      })
    },
    onRouteQR() {
      this.modalShow = !this.modalShow
    },
    async onSubmitWompi() {
      // eslint-disable-next-line
      const checkout = new WidgetCheckout({
        currency: this.currency,
        amountInCents: `${this.amount_in_cents}00`,
        signatureIntegrity: this.data_signature_integrity,
        reference: this.reference,
        publicKey: this.public_key,
        customerData: { // Opcional
          email: this.email,
          fullName: this.full_name,
          phoneNumber: this.optionsTypePayment.find(element => element.id === this.travelData.type_payment_id).phone_number,
          phoneNumberPrefix: '+57',
          legalId: this.legal_id,
          legalIdType: this.legal_id_type,
        },
      })
      checkout.open(result => {
        window.location.replace(`/payments/result/${result.transaction.id}`)
      })
    },
  },
}

</script>

<style scope>
.vd-picker__input {
  border: 1px solid #d8d6de;
  padding: 0.1rem 0.7rem;
}
</style>
